import React from 'react'
import Helmet from 'react-helmet'
import styles from './base.module.css'

import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import Chrome from '../../components/Chrome'

import screenshot from '../../../assets/feedback-portal-large.png'
import data from '../../../data/comparison.yaml'

export default class Page extends React.Component {
  renderItem = (item) => {
    return (
      <div className={styles.reason}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.us}>{item.us}</div>
        <div className={styles.them}>{item.them}</div>
      </div>
    )
  }

  render() {
    const dataItems = data.uservoice

    return (
      <Layout>
        <div className={styles.root}>
          <Helmet title={'UserVoice alternative - Suggested'}></Helmet>

          <div className={styles.intro}>
            <h1>UserVoice alternative</h1>
            <h3>Try Suggested, designed for product people.</h3>
          </div>

          <div className={styles.lead}>
            <p>
              Suggested was born out of necessity. Built by a serial entrepreneur
              to manage customer feedback and product development for multiple
              projects.
            </p>
            <p>
              We've don't really consider ourselves a UserVoice alternative,
              we're designed for startups and agile companies that want to get
              started right away, rather than large enterprises.
            </p>
            <p>
              Designed to be lightweight, cost-effective, with full support for
              customer feedback, roadmaps, and changelogs.
            </p>
          </div>

          <div className={styles.screenshot}>
            <Chrome>
              <img alt="screenshot" src={screenshot} />
            </Chrome>
          </div>

          <div className={styles.comparison}>
            <h1>Why our customers love Suggested</h1>
            <div className={styles.items}>
              {dataItems.map((item) => this.renderItem(item))}
            </div>
          </div>

          <div className="signup-container">
            <SignupButton />
          </div>
        </div>
      </Layout>
    )
  }
}
